"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transactionWithoutIdsSchema = exports.transactionSchema = exports.categorizationTypeSchema = void 0;
const zod_1 = require("zod");
const account_category_1 = require("./account-category");
const account_type_1 = require("./account-type");
exports.categorizationTypeSchema = zod_1.z.enum(['plaid', 'rule', 'manual']);
exports.transactionSchema = zod_1.z.object({
    id: zod_1.z.string(),
    wizefiId: zod_1.z.string(),
    sourceAccountId: zod_1.z.optional(zod_1.z.string()),
    amount: zod_1.z.number(),
    plaidCategory: zod_1.z.optional(zod_1.z.object({
        primary: zod_1.z.string(),
        detailed: zod_1.z.string()
    })),
    date: zod_1.z.string(),
    isManual: zod_1.z.boolean(),
    name: zod_1.z.string(),
    originalAmount: zod_1.z.number(),
    originalCurrencyCode: zod_1.z.optional(zod_1.z.string()),
    plaidId: zod_1.z.optional(zod_1.z.string()),
    isDeleted: zod_1.z.boolean(),
    isPending: zod_1.z.optional(zod_1.z.boolean()),
    isModified: zod_1.z.optional(zod_1.z.boolean()),
    isDuplicated: zod_1.z.optional(zod_1.z.boolean()),
    type: zod_1.z.optional(account_type_1.accountTypeSchema),
    category: zod_1.z.optional(account_category_1.accountCategorySchema),
    account: zod_1.z.optional(zod_1.z.string()),
    duplicatedCheckCompleted: zod_1.z.optional(zod_1.z.boolean()),
    /**
     * If this transaction is split, this id indicates the id of its parent transaction
     */
    parentId: zod_1.z.optional(zod_1.z.string()),
    /**
     * If true, this indicates this transaction was split and has children below it
     */
    hasChildren: zod_1.z.optional(zod_1.z.boolean()),
    hasChildrenDeleted: zod_1.z.optional(zod_1.z.boolean()),
    isModificationCheckedByUser: zod_1.z.optional(zod_1.z.boolean()),
    categorizationType: zod_1.z.optional(exports.categorizationTypeSchema),
    budgetShadowCategory: zod_1.z.optional(account_category_1.accountCategorySchema)
});
exports.transactionWithoutIdsSchema = exports.transactionSchema.omit({
    id: true,
    wizefiId: true
});
