/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Transaction } from '@wizefi/entities';
import { PlaidService } from '@app/services/plaid.service';
import { TransactionService } from '@app/services/transaction.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { concat, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, toArray } from 'rxjs/operators';
import { promoteDraftRequested } from '../draft/loaded-draft.actions';
import { logoutRequested } from '../screen.actions';
import { selectSelectedMonth } from '../selected-month/selected-month.selectors';
import { ruleCreationRequested, ruleUpdateRequested } from '../transaction-rules/transaction-rules.actions';
import {
  lastMonthTransactionsGetFailed,
  lastMonthTransactionsGetRequested,
  lastMonthTransactionsGetSuccessful,
  splitTransactionsFailed,
  splitTransactionsRequested,
  syncTransactionsFailed,
  syncTransactionsRequested,
  getFilteredSyncedTransactionsRequested,
  syncTransactionsSuccessful,
  transactionCreationFailed,
  transactionCreationRequested,
  transactionCreationSuccessful,
  getFilteredSyncedTransactionsSuccessful,
  getFilteredSyncedTransactionsFailed,
  transactionsBatchCreationSuccessful,
  transactionsBatchDeleteSuccessful,
  transactionsBatchUpdateFailed,
  transactionsBatchUpdateRequested,
  transactionsBatchUpdateSuccessful,
  transactionsGetFailed,
  transactionsGetRequested,
  transactionsGetSuccessful,
  transactionUpdateFailed,
  transactionUpdateRequested,
  transactionUpdateSuccessful
} from './transaction.actions';
import { AccountActions } from '../account/account.actions';
import { institutionDeleteSuccessful } from '../institution/institution.actions';

@Injectable()
export class TransactionEffects {
  transactionsGetEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(transactionsGetRequested),
      concatLatestFrom(() => this.store.select(selectSelectedMonth)),
      mergeMap(([props, selectedMonth]) =>
        this.transactionService.getTransactions(props.yearMonth ?? selectedMonth).pipe(
          map(transactions => transactionsGetSuccessful({ transactions })),
          catchError(err => of(transactionsGetFailed({ err })))
        )
      )
    );
  });

  transactionsUpdateEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(transactionUpdateRequested),
      concatLatestFrom(() => this.store.select(selectSelectedMonth)),
      mergeMap(([props, selectedMonth]) =>
        this.transactionService.editTransaction(props.transaction).pipe(
          map(transaction => transactionUpdateSuccessful({ transaction, selectedMonth })),
          catchError(err => of(transactionUpdateFailed({ err })))
        )
      )
    );
  });

  transactionsCreationEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(transactionCreationRequested),
      mergeMap(props =>
        this.transactionService.createTransaction(props.transaction).pipe(
          concatLatestFrom(() => this.store.select(selectSelectedMonth)),
          map(([transaction, selectedMonth]) => transactionCreationSuccessful({ transaction, selectedMonth })),
          catchError(error => of(transactionCreationFailed({ error })))
        )
      )
    );
  });

  transactionsBatchUpdateEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(transactionsBatchUpdateRequested),
      mergeMap(props =>
        this.transactionService.editBatchTransactions(props.transactions).pipe(
          map(transactions => transactionsBatchUpdateSuccessful({ transactions })),
          catchError(err => of(transactionsBatchUpdateFailed({ err })))
        )
      )
    );
  });

  syncTransactionsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(syncTransactionsRequested),
      concatLatestFrom(() => this.store.select(selectSelectedMonth)),
      mergeMap(([props, selectedMonth]) =>
        this.plaidService.syncTransactions(props.yearMonth ?? selectedMonth).pipe(
          map(transactions => syncTransactionsSuccessful({ transactions })),
          catchError(err => of(syncTransactionsFailed({ err })))
        )
      )
    );
  });

  getFilteredSyncedTransactionsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getFilteredSyncedTransactionsRequested),
      concatLatestFrom(() => this.store.select(selectSelectedMonth)),
      mergeMap(([props, selectedMonth]) =>
        this.transactionService.getFilteredSyncedTransactions(props.yearMonth ?? selectedMonth).pipe(
          map(transactions => getFilteredSyncedTransactionsSuccessful({ transactions })),
          catchError(err => of(getFilteredSyncedTransactionsFailed({ err })))
        )
      )
    );
  });

  splitTransactionsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(splitTransactionsRequested),
      switchMap(props =>
        concat(
          this.transactionService.editTransaction(props.editTransaction),
          this.transactionService.deleteBatchTransactions(props.deleteTransactions),
          this.transactionService.createBatchTransactions(props.newTransactions)
        ).pipe(
          toArray(),
          concatLatestFrom(() => this.store.select(selectSelectedMonth)),
          switchMap(
            ([[updatedTransaction, deletedTransactions, newTransactions], selectedMonth]: [[Transaction, Transaction[], Transaction[]], string]) =>
              of(
                transactionUpdateSuccessful({ transaction: updatedTransaction, selectedMonth }),
                transactionsBatchDeleteSuccessful({ transactions: deletedTransactions }),
                transactionsBatchCreationSuccessful({ transactions: newTransactions, selectedMonth })
              )
          ),
          catchError(err => of(splitTransactionsFailed({ err })))
        )
      )
    );
  });

  lastMonthTransactionsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(lastMonthTransactionsGetRequested),
      switchMap(props =>
        this.transactionService.getFilteredSyncedTransactions(moment(props.yearMonth).subtract(1, 'months').toISOString().substring(0, 7)).pipe(
          map(transactions => lastMonthTransactionsGetSuccessful({ transactions })),
          catchError(err => of(lastMonthTransactionsGetFailed({ err })))
        )
      )
    );
  });

  accountsUpdatedOrDeletedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.updateBatchSuccessful, AccountActions.deleteSuccessful, AccountActions.updateSuccessful, institutionDeleteSuccessful),
      tap(() => this.transactionService.clearCache()),
      map(() => syncTransactionsRequested({}))
    );
  });

  clearTransactionsCacheEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(logoutRequested, promoteDraftRequested, ruleCreationRequested, ruleUpdateRequested),
        tap(() => this.transactionService.clearCache())
      );
    },
    { dispatch: false }
  );

  public constructor(
    private actions$: Actions,
    private transactionService: TransactionService,
    private plaidService: PlaidService,
    private store: Store
  ) {}
}
