import { AfterViewInit, Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators, ValidatorFn } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule, MatFormField } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { StructureModule } from '../structure/structure.module';
import { PillComponent } from '@app/components/pill.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CommonModule } from '@angular/common';
import { AccountTypeService, SubtypeWithProductivity } from '@app/services/account-type/account-type.service';
import { Account, AccountCategory, AccountType, AccountWithoutId, allCategories } from '@wizefi/entities';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, first, map, startWith } from 'rxjs/operators';
import { DialogService } from '@app/services/dialog.service';
import { Store } from '@ngrx/store';
import { AccountActions } from '@app/state/account/account.actions';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { selectManualInstitution } from '@app/state/institution/institution.selectors';
import { AccountService } from '@app/services/account.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { selectIsInCurrentMonth } from '@app/state/selected-month/selected-month.selectors';
import { institutionCreationRequest } from '@app/state/institution/institution.actions';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-account-dialog',
  templateUrl: './edit-account-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    StructureModule,
    PillComponent,
    MatSlideToggleModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatDatepickerModule
  ]
})
export class EditAccountDialogComponent implements OnInit, AfterViewInit {
  subtypes = this.accountTypeService.getAllSubtypes();
  categories = allCategories;

  private subtypeValidator: ValidatorFn = control =>
    this.validSubtype(control.value, this.formGroup?.controls?.type?.value) ? null : { subtype: true };

  formGroup = new FormGroup({
    enableTransactions: new FormControl(this.account?.shouldSyncTransactions ?? true, { nonNullable: true }),
    active: new FormControl(this.account?.isActive ?? true, { nonNullable: true }),
    name: new FormControl(this.account?.accountName, { validators: Validators.required }),
    type: new FormControl<AccountType | undefined>(this.account?.type, { validators: Validators.required }),
    subtype: new FormControl<SubtypeWithProductivity | string | undefined>(
      this.subtypes.find(subtype => subtype.subtype === this.account?.subtype) ?? '',
      { validators: [Validators.required, this.subtypeValidator] }
    ),
    balance: new FormControl(this.account?.balance),
    interestRate: new FormControl(this.account?.interestRate),
    targetBalance: new FormControl(this.account?.targetAmount),
    paidInFull: new FormControl(this.account?.paidInFullEachMonth ?? false, { nonNullable: true }),
    selfContribution: new FormControl(this.account?.monthlyAmount),
    outsideContribution: new FormControl(this.account?.employerContribution),
    income: new FormControl(this.account?.monthlyIncome),
    paidInFullEachMonth: new FormControl(this.account?.paidInFullEachMonth),
    minimumPayment: new FormControl(this.account?.monthlyMinimum),
    extraPayment: new FormControl(this.account ? this.account.monthlyAmount - this.account.monthlyMinimum : null),
    premium: new FormControl(this.account?.monthlyAmount),
    expirationDate: new FormControl<Date | string | undefined>(
      this.account?.expirationDate && moment(this.account.expirationDate).isValid()
        ? moment(this.account.expirationDate + 'T00:00:00').toDate()
        : undefined
    ),
    coverage: new FormControl(this.account?.coverageAmount)
  });

  filteredSubtypes$: Observable<SubtypeWithProductivity[]>;

  @ViewChildren(MatFormField) formFields: QueryList<MatFormField>;
  @ViewChild('subtypeEl') subtypeEl: ElementRef<HTMLInputElement>;

  constructor(
    private accountTypeService: AccountTypeService,
    @Inject(MAT_DIALOG_DATA) public account: Account | undefined,
    private dialogService: DialogService,
    private store: Store,
    private dialogRef: MatDialogRef<EditAccountDialogComponent>,
    private accountService: AccountService
  ) {}

  async ngOnInit() {
    this.filteredSubtypes$ = combineLatest([
      this.formGroup.controls.subtype.valueChanges.pipe(startWith(this.account?.subtype ?? '')),
      this.formGroup.controls.type.valueChanges.pipe(startWith(this.account?.type))
    ]).pipe(map(([subtype, type]) => this.filterSubtypes(subtype, type)));

    this.formGroup.controls.type.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      this.formGroup.controls.subtype.setValue('');
    });

    this.formGroup.controls.subtype.valueChanges
      .pipe(
        map(subtype => this.getSingleSubtype(subtype, this.formGroup.controls.type.value)),
        filter(subtype => !!subtype),
        distinctUntilChanged()
      )
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .subscribe(subtype => this.recalculateValidators(subtype!));

    if (isSubtype(this.formGroup.controls.subtype.value)) {
      // Calculate initial validators based on initial subtype from account
      this.recalculateValidators(this.formGroup.controls.subtype.value);
    }

    const currentMonthIsSelected = await this.store.select(selectIsInCurrentMonth).pipe(first()).toPromise();
    if (currentMonthIsSelected && this.account?.isManual === false) {
      this.formGroup.controls.balance.disable();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.formFields.forEach(ff => ff.updateOutlineGap()), 1000);
  }

  async onSubmit() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) {
      return;
    }

    const subtypeObj = this.getSingleSubtype(this.formGroup.controls.subtype.value, this.formGroup.controls.type.value);

    if (!subtypeObj || !this.formGroup.controls.type.value) {
      return;
    }

    const accountFromFields = {
      accountName: this.formGroup.controls.name.value ?? '',
      type: this.formGroup.controls.type.value,
      subtype: subtypeObj.subtype,
      category: subtypeObj.category,
      budgetSubcategory: subtypeObj.shadowCategory,
      productivity: subtypeObj.defaultProductivity,
      balance: this.formGroup.controls.balance.value ?? 0,
      interestRate: this.formGroup.controls.interestRate.value ?? 0,
      accountLimit: 0,
      monthlyAmount: 0,
      employerContribution: 0,
      coverageAmount: 0,
      isActive: this.formGroup.controls.active.value,
      shouldSyncTransactions: this.formGroup.controls.enableTransactions.value,
      monthlyIncome: 0,
      monthlyMinimum: 0,
      isBankAccount: true,
      needsActivation: false,
      expirationDate: undefined,
      paidInFullEachMonth: undefined,
      targetAmount: undefined,
      totalOwed: 0
    };

    const account = { ...(this.account ?? {}), ...accountFromFields };
    if (!this.account) {
      account.isManual = true;

      const existingManualInstitution = await this.store.select(selectManualInstitution).pipe(first()).toPromise();
      const manualInstitution = existingManualInstitution ?? this.accountService.generateManualInstitution();

      if (!existingManualInstitution) {
        this.store.dispatch(institutionCreationRequest({ institution: manualInstitution }));
      }

      account.itemId = manualInstitution.itemId;
    }

    this.fillAccountFields(account);

    if (!!this.account) {
      this.store.dispatch(AccountActions.updateRequested({ account: account as Account }));
    } else {
      this.store.dispatch(AccountActions.createRequested({ partialValues: account }));
    }
    this.dialogRef.close();
  }

  private filterSubtypes(
    value: string | SubtypeWithProductivity | null | undefined,
    type: AccountType | undefined | null
  ): SubtypeWithProductivity[] {
    if (!type) {
      return [];
    }

    const filterValue = typeof value === 'string' ? value.toLowerCase() : !value ? '' : value.subtype.toLowerCase();
    const subtypeLabelMatches = (subtype: SubtypeWithProductivity) => subtype.subtype.toLowerCase().includes(filterValue);
    const categoryLabelMatches = (subtype: SubtypeWithProductivity) =>
      this.categories
        .find(category => category.key === subtype.category)
        ?.label.toLowerCase()
        .includes(filterValue);

    return this.subtypes.filter(subtype => subtype.type === type).filter(subtype => subtypeLabelMatches(subtype) || categoryLabelMatches(subtype));
  }

  selectType(type: AccountType) {
    const differentTypeSelected = type !== this.formGroup.controls.type.value;
    if (differentTypeSelected) {
      this.formGroup.controls.type.setValue(type);
      this.subtypeEl.nativeElement.focus();
    }
  }

  displayFn(subtype: SubtypeWithProductivity): string {
    return subtype.subtype;
  }

  isCategory(category: AccountCategory) {
    const subtype = this.formGroup.controls.subtype.value;
    const validSubtype = this.getSingleSubtype(subtype, this.formGroup.controls.type.value);
    return validSubtype && validSubtype.category === category;
  }

  async confirmAccountDeletion(account: Account) {
    const hasConfirmed = await this.dialogService.openDeleteAccountConfirmationDialog().afterClosed().toPromise();
    if (!hasConfirmed) {
      return;
    }

    this.store.dispatch(AccountActions.deleteRequested({ account }));
    this.dialogRef.close();
  }

  private validSubtype(val: string | undefined | null | SubtypeWithProductivity, type: AccountType | undefined | null): boolean {
    return !!this.getSingleSubtype(val, type);
  }

  getSingleSubtype(
    val: string | undefined | null | SubtypeWithProductivity,
    type: AccountType | undefined | null
  ): SubtypeWithProductivity | undefined {
    if (!val || !type) {
      return undefined;
    }
    if (isSubtype(val)) {
      return val;
    }
    const results = this.filterSubtypes(val, type);
    return results.find(r => r.subtype === val);
  }

  private recalculateValidators(subtype: SubtypeWithProductivity) {
    this.formGroup.controls.balance.clearValidators();
    this.formGroup.controls.interestRate.clearValidators();
    this.formGroup.controls.premium.clearValidators();
    this.formGroup.controls.targetBalance.clearValidators();
    this.formGroup.controls.expirationDate.clearValidators();

    if (subtype.type !== 'assetProtection') {
      this.formGroup.controls.balance.addValidators(Validators.required);
      this.formGroup.controls.interestRate.addValidators(Validators.required);
    } else {
      this.formGroup.controls.premium.addValidators(Validators.required);
    }
    if (subtype.category === 'emergencySavings' || subtype.category === 'cashReserves') {
      this.formGroup.controls.targetBalance.addValidators(Validators.required);
    }
    if (subtype.category === 'termInsurance' || subtype.category === 'otherInsurance') {
      const validDate: ValidatorFn = control => (moment(control.value).isValid() ? null : { valid: true });
      this.formGroup.controls.expirationDate.addValidators(validDate);
    }
    this.formGroup.controls.balance.updateValueAndValidity();
    this.formGroup.controls.interestRate.updateValueAndValidity();
    this.formGroup.controls.premium.updateValueAndValidity();
    this.formGroup.controls.targetBalance.updateValueAndValidity();
    this.formGroup.controls.expirationDate.updateValueAndValidity();
  }

  targetBalanceTooltipText() {
    const commonText =
      'WizeFi uses the target balance as the maximum balance for the account. Any excess is planned to be redistributed to other accounts following the 4 step plan.';
    if (this.isCategory('emergencySavings')) {
      return `${commonText} A minimum of $2,000 is recommended as a target balance for Emergency Savings accounts.`;
    } else if (this.isCategory('cashReserves')) {
      return `${commonText} A minimum of $15,000 is recommended as a target balance for General Savings accounts.`;
    }
    return commonText;
  }

  private fillAccountFields(account: AccountWithoutId) {
    account.paidInFullEachMonth = account.category === 'creditCard' ? this.formGroup.controls.paidInFull.value : undefined;

    if (account.type === 'liabilities') {
      account.accountLimit = this.account?.accountLimit ?? 0;
      account.totalOwed = this.account?.totalOwed ?? 0;
      account.monthlyAmount = (this.formGroup.controls.minimumPayment.value ?? 0) + (this.formGroup.controls.extraPayment.value ?? 0);
      account.monthlyMinimum = this.formGroup.controls.minimumPayment.value ?? 0;

      if (!!account.paidInFullEachMonth) {
        account.monthlyAmount = 0;
        account.monthlyMinimum = 0;
      }
    }

    if (account.type === 'assets') {
      const assetCategoriesWithContribution = new Set<AccountCategory | undefined>(['emergencySavings', 'cashReserves', 'investments']);
      if (assetCategoriesWithContribution.has(account.category)) {
        account.monthlyAmount = this.formGroup.controls.selfContribution.value ?? 0;
        account.employerContribution = this.formGroup.controls.outsideContribution.value ?? 0;
      } else {
        account.monthlyAmount = 0;
        account.employerContribution = 0;
      }

      if (account.category === 'emergencySavings' || account.category === 'cashReserves') {
        account.targetAmount = this.formGroup.controls.targetBalance.value ?? 0;
      }

      if (account.category === 'investmentProperty') {
        account.monthlyIncome = this.formGroup.controls.income.value ?? 0;
      }
    }

    if (account.type === 'assetProtection') {
      account.coverageAmount = this.formGroup.controls.coverage.value ?? 0;
      account.interestRate = 0;
      account.monthlyAmount = this.formGroup.controls.premium.value ?? 0;
      account.monthlyMinimum = account.monthlyAmount;

      if (account.category === 'termInsurance' || account.category === 'otherInsurance') {
        if (this.formGroup.controls.expirationDate.value instanceof Date) {
          account.expirationDate = this.formGroup.controls.expirationDate.value.toISOString().substring(0, 10);
        } else if (typeof this.formGroup.controls.expirationDate.value === 'string') {
          account.expirationDate = this.formGroup.controls.expirationDate.value.substring(0, 10);
        }
      }
    }
  }
}

const isSubtype = (val: unknown): val is SubtypeWithProductivity =>
  !!val && typeof val === 'object' && 'type' in val && 'category' in val && 'subtype' in val;
