/* eslint-disable no-console */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '@app/services/login.service';
import { MessageService } from '@app/services/message/message.service';
import { UserDataSelectors } from '@app/state/user-data/user-data.selectors';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { LOGIN_PATH } from '../route-paths';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  public static readonly freeTrialSignupMode = 'signup';
  public static readonly subscriptionSignupMode = 'subscription';

  public signupMode!: string;
  public signupComponentType = SignupComponent;
  public affiliateId = '';
  public affiliateName = '';
  public parentAffiliateId = '';
  public parentaffiliateData: {
    firstName: string;
    lastName: string;
    wizefiId: string;
    isEnterpriseParent?: boolean;
    isEnterpriseMember?: boolean;
    isActiveEnterpriseContract?: boolean;
    isEnterpriseMemberActive?: boolean;
  } = { firstName: '', lastName: '', wizefiId: '' };

  //Think a way to manage this validations here. Indentation.
  public freeTrialId = 'freetrial';
  trialDays = '30-Days';
  grahamAffiliateCode = '0833b24';
  bluprint = false;
  public isDefaultInvite = false;
  inCCEntryMode = false;
  urlRoute = '';
  public isLoading = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private loginService: LoginService,
    private messageService: MessageService,
    private router: Router
  ) {}

  public async ngOnInit() {
    this.isLoading = true;
    this.inCCEntryMode = false;
    if (this.router.url === '/subscription') {
      this.inCCEntryMode = true;
    }

    this.signupMode = this.activatedRoute.snapshot.data.mode;

    const affiliateAlias = this.activatedRoute.snapshot.queryParamMap.get('a');
    if (affiliateAlias) {
      this.isDefaultInvite = affiliateAlias === this.freeTrialId;
      this.affiliateId = affiliateAlias;
      this.checkAffiliateCode(affiliateAlias);
    }

    try {
      this.parentaffiliateData = await this.getParentAffiliateInfo();
      this.setParentData(this.parentaffiliateData);
      if (this.parentaffiliateData.wizefiId === '5383792') {
        this.bluprint = true;
      } else {
        this.bluprint = false;
      }
    } catch (error) {
      this.parentaffiliateData = {
        firstName: '',
        lastName: '',
        wizefiId: ''
      };

      this.bluprint = false;
    } finally {
      this.isLoading = false;
    }
  }

  //TODO: this methos is related with the Jira Card WIZ-2430
  checkAffiliateCode(affiliateAlias: string): string {
    return affiliateAlias === this.grahamAffiliateCode ? (this.trialDays = '60-Days') : this.trialDays;
  }

  private setParentData(parentData: { firstName: string; lastName: string; wizefiId: string }) {
    if (!parentData.wizefiId) {
      this.messageService.error(`Affiliate ${this.affiliateId} not found`);
      this.router.navigate([LOGIN_PATH]);
    }

    this.affiliateName = parentData.firstName + ' ' + parentData.lastName;
    this.parentAffiliateId = parentData.wizefiId;
  }

  private async getParentAffiliateInfo() {
    if (this.affiliateId) {
      const data = await this.loginService.getUserInfoFromAlias(this.affiliateId).toPromise();
      console.log('Data received: ', data);
      return {
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        wizefiId: data.wizefiId || '',
        isEnterpriseMember: data.isEnterpriseMember || false,
        isEnterpriseParent: data.isEnterpriseParent || false,
        isActiveEnterpriseContract: data.isActiveEnterpriseContract || false,
        isEnterpriseMemberActive: data.isEnterpriseMemberActive || false
      };
    }

    const userData = await this.store.pipe(UserDataSelectors.selectLoadedUserData).pipe(first()).toPromise();
    if (userData?.parentAffiliateID) {
      const data = await this.loginService.getUserInfoFromId(userData.parentAffiliateID).toPromise();
      return {
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        wizefiId: data.wizefiId || '',
        isEnterpriseMember: data.isEnterpriseMember || false,
        isEnterpriseParent: data.isEnterpriseParent || false,
        isActiveEnterpriseContract: data.isActiveEnterpriseContract || false,
        isEnterpriseMemberActive: data.isEnterpriseMemberActive || false
      };
    }

    throw new Error('No parent id or affiliate alias was set');
  }
}
