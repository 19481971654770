import { Transaction, TransactionWithoutIds } from '@wizefi/entities';
import { createAction, props } from '@ngrx/store';

export const lastMonthTransactionsGetRequested = createAction('[App] Last month transactions were requested', props<{ yearMonth: Date }>());
export const lastMonthTransactionsGetSuccessful = createAction(
  '[App] Last month transactions get was successful',
  props<{ transactions: Transaction[] }>()
);
export const lastMonthTransactionsGetFailed = createAction('[App] Last month transactions get failed', props<{ err: any }>());
export const transactionsGetRequested = createAction('[App] Transactions were requested', props<{ yearMonth?: string }>());
export const transactionsGetSuccessful = createAction('[App] Transactions get was successful', props<{ transactions: Transaction[] }>());
export const transactionsGetFailed = createAction('[App] Transactions get failed', props<{ err: any }>());

export const syncTransactionsRequested = createAction('[App] sync transactions was requested', props<{ yearMonth?: string }>());
export const getFilteredSyncedTransactionsRequested = createAction(
  '[App] get filtered synced transactions was requested',
  props<{ yearMonth?: string }>()
);

export const syncTransactionsSuccessful = createAction('[App] sync transactions was successful', props<{ transactions: Transaction[] }>());
export const getFilteredSyncedTransactionsSuccessful = createAction(
  '[App] getting filtered synced transactions was successful',
  props<{ transactions: Transaction[] }>()
);

export const syncTransactionsFailed = createAction('[App] sync transactions failed', props<{ err: any }>());
export const getFilteredSyncedTransactionsFailed = createAction('[App] getting filtered synced transactions failed', props<{ err: any }>());

export const transactionsBatchCreationSuccessful = createAction(
  '[App] Transactions batch creation was successful',
  props<{ transactions: Transaction[]; selectedMonth: string }>()
);

export const transactionsBatchUpdateRequested = createAction(
  '[App] Transactions batch update was requested',
  props<{ transactions: Transaction[] }>()
);
export const transactionsBatchUpdateSuccessful = createAction(
  '[App] Transactions batch update was successful',
  props<{ transactions: Transaction[] }>()
);
export const transactionsBatchUpdateFailed = createAction('[App] Transactions batch update failed', props<{ err: any }>());

export const transactionsBatchDeleteSuccessful = createAction(
  '[App] Transactions batch delete was successful',
  props<{ transactions: Transaction[] }>()
);

export const transactionUpdateRequested = createAction('[App] update transaction requested', props<{ transaction: Transaction }>());
export const transactionUpdateSuccessful = createAction(
  '[App] update transaction successful',
  props<{ transaction: Transaction; selectedMonth: string }>()
);
export const transactionUpdateFailed = createAction('[App] update transaction failed', props<{ err: any }>());

export const transactionCreationRequested = createAction('[App] creation transaction requested', props<{ transaction: TransactionWithoutIds }>());
export const transactionCreationSuccessful = createAction(
  '[App] creation transaction successful',
  props<{ transaction: Transaction; selectedMonth: string }>()
);
export const transactionCreationFailed = createAction('[App] creation transaction failed', props<{ error: any }>());
export const splitTransactionsRequested = createAction(
  '[EditTransactionCategory] split transaction',
  props<{ newTransactions: TransactionWithoutIds[]; deleteTransactions: Transaction[]; editTransaction: Transaction }>()
);
export const splitTransactionsFailed = createAction('[EditTransactionCategory] split transaction failed', props<{ err: any }>());
