"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productivityByCategory = exports.allSubtypeInfo = exports.accountWithoutIdSchema = exports.accountSchema = exports.productivitySchema = void 0;
const zod_1 = require("zod");
const account_category_1 = require("./account-category");
const account_type_1 = require("./account-type");
exports.productivitySchema = zod_1.z.enum(['Limited', 'Non-productive', 'Productive']);
exports.accountSchema = zod_1.z.object({
    id: zod_1.z.string(),
    accountLimit: zod_1.z.number(),
    accountName: zod_1.z.string(),
    budgetSubcategory: zod_1.z.optional(account_category_1.accountCategorySchema),
    coverageAmount: zod_1.z.number(),
    expirationDate: zod_1.z.optional(zod_1.z.string()),
    monthlyMinimum: zod_1.z.number(),
    monthlyAmount: zod_1.z.number(),
    monthlyIncome: zod_1.z.number(),
    type: zod_1.z.optional(account_type_1.accountTypeSchema),
    category: zod_1.z.optional(account_category_1.accountCategorySchema),
    interestRate: zod_1.z.number(),
    balance: zod_1.z.number(),
    employerContribution: zod_1.z.number(),
    productivity: zod_1.z.optional(exports.productivitySchema),
    targetAmount: zod_1.z.optional(zod_1.z.number()),
    totalOwed: zod_1.z.number(),
    paidInFullEachMonth: zod_1.z.optional(zod_1.z.boolean()),
    isBankAccount: zod_1.z.boolean(),
    plaidId: zod_1.z.optional(zod_1.z.string()),
    subtype: zod_1.z.optional(zod_1.z.string()),
    balanceDate: zod_1.z.optional(zod_1.z.string()),
    isActive: zod_1.z.boolean(),
    shouldSyncTransactions: zod_1.z.optional(zod_1.z.boolean()),
    needsActivation: zod_1.z.optional(zod_1.z.boolean()),
    isManual: zod_1.z.optional(zod_1.z.boolean()),
    itemId: zod_1.z.optional(zod_1.z.string()),
    mask: zod_1.z.optional(zod_1.z.string()),
    originalBalance: zod_1.z.optional(zod_1.z.number()),
    originalCurrencyCode: zod_1.z.optional(zod_1.z.string())
});
exports.accountWithoutIdSchema = exports.accountSchema.omit({ id: true });
const _allSubtypeInfo = [
    { type: 'assets', category: 'emergencySavings', subtype: 'Emergency Savings' },
    { type: 'assets', category: 'cash', subtype: 'Cash' },
    { type: 'assets', category: 'cashReserves', subtype: 'General Savings' },
    { type: 'assets', category: 'checking', subtype: 'Checking' },
    { type: 'assets', category: 'investments', subtype: 'IRA' },
    { type: 'assets', category: 'investments', subtype: 'Roth IRA' },
    { type: 'assets', category: 'investments', subtype: 'SEP IRA' },
    { type: 'assets', category: 'investments', subtype: 'Simple IRA' },
    { type: 'assets', category: 'investments', subtype: '401(k)' },
    { type: 'assets', category: 'investments', subtype: 'Roth 401(k)' },
    { type: 'assets', category: 'investments', subtype: '403(b)' },
    { type: 'assets', category: 'investments', subtype: '457(b)' },
    { type: 'assets', category: 'investments', subtype: 'Annuity' },
    { type: 'assets', category: 'investments', subtype: 'Brokerage Account' },
    { type: 'assets', category: 'investments', subtype: 'HSA' },
    { type: 'assets', category: 'investments', subtype: 'Certificate of Deposit' },
    { type: 'assets', category: 'investments', subtype: 'Money Market' },
    { type: 'assets', category: 'investments', subtype: '529' },
    { type: 'assets', category: 'investments', subtype: 'Business You Own' },
    { type: 'assets', category: 'investments', subtype: 'Other Productive Asset' },
    { type: 'assets', category: 'residenceProperty', subtype: 'Non-Income-Producing Real Estate' },
    { type: 'assets', category: 'investmentProperty', subtype: 'Farm' },
    { type: 'assets', category: 'investmentProperty', subtype: 'Land Investment' },
    { type: 'assets', category: 'personalProperty', subtype: 'Appliance' },
    { type: 'assets', category: 'personalProperty', subtype: 'Books/CDs/DVD/Tapes' },
    { type: 'assets', category: 'personalProperty', subtype: 'Camera' },
    { type: 'assets', category: 'personalProperty', subtype: 'Car' },
    { type: 'assets', category: 'personalProperty', subtype: 'Clothing' },
    { type: 'assets', category: 'personalProperty', subtype: 'Computer' },
    { type: 'assets', category: 'personalProperty', subtype: 'Cookware' },
    { type: 'assets', category: 'personalProperty', subtype: 'Cutlery/Silverware' },
    { type: 'assets', category: 'personalProperty', subtype: 'Decorations' },
    { type: 'assets', category: 'personalProperty', subtype: 'Dishes/Fine China' },
    { type: 'assets', category: 'personalProperty', subtype: 'Electronics' },
    { type: 'assets', category: 'personalProperty', subtype: 'Furniture' },
    { type: 'assets', category: 'personalProperty', subtype: 'Gardening' },
    { type: 'assets', category: 'personalProperty', subtype: 'Glass/Crystal' },
    { type: 'assets', category: 'personalProperty', subtype: 'Jewelry' },
    { type: 'assets', category: 'personalProperty', subtype: 'Motorcycle' },
    { type: 'assets', category: 'personalProperty', subtype: 'Musical Instrument' },
    { type: 'assets', category: 'personalProperty', subtype: 'Rec. Vehicle' },
    { type: 'assets', category: 'personalProperty', subtype: 'Rug' },
    { type: 'assets', category: 'personalProperty', subtype: 'Sporting Goods' },
    { type: 'assets', category: 'personalProperty', subtype: 'Tools' },
    { type: 'assets', category: 'personalProperty', subtype: 'Toys' },
    { type: 'assets', category: 'personalProperty', subtype: 'Boat' },
    { type: 'assets', category: 'personalProperty', subtype: 'Other Nonproductive Asset' },
    { type: 'assets', category: 'limitedPersonalProperty', subtype: 'Art' },
    { type: 'assets', category: 'limitedPersonalProperty', subtype: 'Classic Car' },
    { type: 'assets', category: 'limitedPersonalProperty', subtype: 'Collectible' },
    { type: 'assets', category: 'limitedPersonalProperty', subtype: 'Gold' },
    { type: 'assets', category: 'limitedPersonalProperty', subtype: 'Other Limited Asset' },
    { type: 'assetProtection', category: 'termInsurance', subtype: 'Term Life Insurance', shadowCategory: 'health' },
    { type: 'assetProtection', category: 'permInsurance', subtype: 'Permanent Life Insurance', shadowCategory: 'health' },
    { type: 'assetProtection', category: 'autoInsurance', subtype: 'Automobile Insurance', shadowCategory: 'transportation' },
    { type: 'assetProtection', category: 'autoInsurance', subtype: 'Motorcycle Insurance', shadowCategory: 'transportation' },
    { type: 'assetProtection', category: 'autoInsurance', subtype: 'RV Insurance', shadowCategory: 'transportation' },
    { type: 'assetProtection', category: 'autoInsurance', subtype: 'Other Automobile Insurance', shadowCategory: 'transportation' },
    { type: 'assetProtection', category: 'homeInsurance', subtype: 'Homeowner Insurance', shadowCategory: 'housing' },
    { type: 'assetProtection', category: 'homeInsurance', subtype: 'Renter Insurance', shadowCategory: 'housing' },
    { type: 'assetProtection', category: 'homeInsurance', subtype: 'Other Housing Insurance', shadowCategory: 'housing' },
    { type: 'assetProtection', category: 'otherInsurance', subtype: 'Group Life Insurance', shadowCategory: 'health' },
    { type: 'assetProtection', category: 'otherInsurance', subtype: 'Disability Insurance', shadowCategory: 'health' },
    { type: 'assetProtection', category: 'otherInsurance', subtype: 'Group Disability Insurance', shadowCategory: 'health' },
    { type: 'assetProtection', category: 'otherInsurance', subtype: 'Umbrella', shadowCategory: 'health' },
    { type: 'assetProtection', category: 'otherInsurance', subtype: 'Other Insurance', shadowCategory: 'health' },
    { type: 'liabilities', category: 'mortgageLimited', subtype: 'Mortgage for Non-Income-Producing Real Estate', shadowCategory: 'housing' },
    { type: 'liabilities', category: 'creditCard', subtype: 'Credit Card' },
    { type: 'liabilities', category: 'autoLoan', subtype: 'Auto Loan', shadowCategory: 'transportation' },
    { type: 'liabilities', category: 'boatLoan', subtype: 'Boat Loan', shadowCategory: 'transportation' },
    { type: 'liabilities', category: 'educationalLoan', subtype: 'Educational Loan' },
    { type: 'liabilities', category: 'other', subtype: 'Business loan' },
    { type: 'liabilities', category: 'other', subtype: 'Margin Account' },
    { type: 'liabilities', category: 'other', subtype: 'Other Productive Debt' },
    { type: 'liabilities', category: 'otherLimited', subtype: 'Loan to purchase Limited Asset' },
    { type: 'liabilities', category: 'otherLimited', subtype: 'Other Limited Debt' },
    { type: 'liabilities', category: 'otherNonProductive', subtype: 'Personal Loan' },
    { type: 'liabilities', category: 'otherNonProductive', subtype: 'Other Nonproductive Debt' }
];
exports.allSubtypeInfo = [..._allSubtypeInfo];
exports.productivityByCategory = {
    emergencySavings: 'Limited',
    cashReserves: 'Limited',
    checking: 'Limited',
    cash: 'Limited',
    residenceProperty: 'Limited',
    limitedPersonalProperty: 'Limited',
    mortgageLimited: 'Limited',
    otherLimited: 'Limited',
    personalProperty: 'Non-productive',
    autoLoan: 'Non-productive',
    boatLoan: 'Non-productive',
    creditCard: 'Non-productive',
    educationalLoan: 'Non-productive',
    otherNonProductive: 'Non-productive',
    investments: 'Productive',
    investmentProperty: 'Productive',
    other: 'Productive'
};
