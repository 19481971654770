import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarComponent } from './snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private snackbarRef: MatSnackBarRef<any> | undefined;

  private activeNotifications: any[] = [];

  constructor(private snackbar: MatSnackBar) {}

  success(msg: string, timeout: number = 3000) {
    this.snackbarRef?.dismiss();
    this.snackbarRef = this.snackbar.openFromComponent(SnackbarComponent, this.options(msg, timeout, 'success'));
    this.snackbarRef.afterDismissed().subscribe(() => (this.snackbarRef = undefined));
  }

  error(msg: string, timeout: number = 3000) {
    this.snackbarRef?.dismiss();
    this.snackbarRef = this.snackbar.openFromComponent(SnackbarComponent, this.options(msg, timeout, 'error'));
    this.snackbarRef.afterDismissed().subscribe(() => (this.snackbarRef = undefined));
  }

  info(msg: string, timeout: number = 10000) {
    this.snackbarRef?.dismiss();
    this.snackbarRef = this.snackbar.openFromComponent(SnackbarComponent, this.options(msg, timeout, 'info'));
    this.snackbarRef.afterDismissed().subscribe(() => (this.snackbarRef = undefined));
  }

  private options(msg: string, timeout: number, type: string): MatSnackBarConfig<{ msg: string; type: string }> {
    return {
      data: { msg, type },
      duration: timeout,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: type === 'success' ? 'success-snackbar' : undefined
    };
  }

  clear() {
    this.snackbarRef?.dismiss();
    this.snackbarRef?.afterDismissed().subscribe(() => (this.snackbarRef = undefined));
  }
}
