import { Component, OnInit, ChangeDetectionStrategy, Inject, ElementRef, AfterViewInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-snackbar',
  template: `<mat-icon [ngClass]="[data.type]">{{ iconName }}</mat-icon>
    <div class="content" [innerHTML]="data.msg"></div>
    <button mat-button [color]="'accent'" (click)="snackbarRef.dismiss()" data-test="snackbar-dismiss-button">Dismiss</button>`,
  styles: [
    `
      @import '../../../styles/variables.scss';

      :host {
        display: flex;
        align-items: center;

        mat-icon,
        div.content {
          margin-right: 16px;
        }

        div.content {
          flex: 1;
          text-align: center;
        }

        mat-icon {
          &.error {
            color: $red;
          }
          &.info {
            color: var(--yellow);
          }
        }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackbarComponent implements OnInit, AfterViewInit {
  iconName?: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { msg: string; type: 'error' | 'warn' | 'success' | 'info' },
    public snackbarRef: MatSnackBarRef<SnackbarComponent>,
    private router: Router,
    private elRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.iconName = this.getIcon();
  }

  ngAfterViewInit(): void {
    this.addLinkListener();
  }

  getIcon() {
    switch (this.data.type) {
      case 'error':
        return 'error';
      case 'success':
        return 'done';
      case 'info':
        return 'lightbulb';
      default:
        return '';
    }
  }

  addLinkListener() {
    const link = this.elRef.nativeElement.querySelector('.message-link-transactions-modified');
    const linkAccounts = this.elRef.nativeElement.querySelector('.message-link-accounts-disconnected');
    if (link) {
      link.addEventListener('click', (event: Event) => {
        event.preventDefault();
        this.router.navigate(['/transactions']);
        this.snackbarRef.dismiss();
      });
    }
    if (linkAccounts) {
      linkAccounts.addEventListener('click', (event: Event) => {
        event.preventDefault();
        this.router.navigate(['/accounts']);
        this.snackbarRef.dismiss();
      });
    }
  }
}
