"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.institutionItemTableName = exports.institutionItemSchema = void 0;
const zod_1 = require("zod");
exports.institutionItemSchema = zod_1.z.object({
    itemId: zod_1.z.string(),
    accessToken: zod_1.z.string(),
    wizefiId: zod_1.z.string()
});
exports.institutionItemTableName = 'InstitutionItems';
