export const environment = {
  production: true,
  mode: 'beta',
  apiUrl: 'https://my.wizefi.com/api',
  intercomId: 'elib46tc',
  googleAnalytics: 'G-SHDFV5ST89',
  gaScript: 'https://www.googletagmanager.com/gtag/js?id=G-SHDFV5ST89',

  ga: {
    appName: 'WizeFi',
    version: 1
  }
};
