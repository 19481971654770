import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ThemeService } from 'ng2-charts';
import { AppComponent } from './components/app/app.component';
import { FloorPipe } from './pipes/pipe-floor.class'; // Math.floor
import { CustomErrorHandler } from './utilities/custom-error-handler.class';

import { AppRoutingModule } from './modules/routing.module';
import { StructureModule } from './modules/structure/structure.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInterceptor } from '@app/interceptor/app-interceptor';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { AppState } from './state/app.state';
import { institutionReducer } from './state/institution/institution.reducer';
import { InstitutionEffects } from './state/institution/institution.effects';
import { ScreenEffects } from './state/screen.effects';
import { draftInfoReducer } from './state/draft/draft-info.reducer';
import { ReactiveComponentModule } from '@ngrx/component';
import { loadedDraftReducer } from './state/draft/loaded-draft.reducer';
import { DraftInfoEffects } from './state/draft/draft-info.effects';
import { LoadedDraftEffects } from './state/draft/loaded-draft.effects';
import { transactionReducer } from './state/transaction/transaction.reducer';
import { TransactionEffects } from './state/transaction/transaction.effects';
import { selectedMonthReducer } from './state/selected-month/selected-month.reducer';
import { SelectedMonthEffects } from './state/selected-month/selected-month.effects';
import { TransactionRulesEffects } from './state/transaction-rules/transaction-rules.effects';
import { transactionRulesReducer } from './state/transaction-rules/transaction-rules.reducer';
import { spendingComponentReducer } from './state/component/spending/spending.reducer';
import { subscriptionReducer } from './state/subscription/subscription.reducer';
import { SubscriptionEffects } from './state/subscription/subscription.effects';
import { LoginEffects } from './state/login/login.effects';
import { loginReducer } from './state/login/login.reducer';
import { accountsReducer } from './state/account/account.reducer';
import { AccountEffects } from './state/account/account.effects';
import { userDataReducer } from './state/user-data/user-data.reducer';
import { UserDataEffects } from './state/user-data/user-data.effects';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { SnackbarModule } from './services/message/snackbar.module';
import { IntercomEffects } from './state/intercom.effects';
import { coachReducer } from './state/coach/coach.reducer';
import { CoachEffects } from './state/coach/coach.effects';
import { payoutRequestReducer } from './state/payout-request/payout-request.reducer';
import { PayoutRequestsEffects } from './state/payout-request/payout-request.effects';
import { reportsCardsReducer } from './state/reports/reports-cards.reducer';
import { ReportsCardsEffects } from './state/reports/reports-cards.effects';
import { trackingToggleReducer } from './state/tracking-toggle/tracking-toggle.reducer';
import { TrackingToggleEffects } from './state/tracking-toggle/tracking-toggle.effects';
import { MatBadgeModule } from '@angular/material/badge';

// Google Analytics Service
import { GoogleAnalyticsService } from './services/google-analytics.service';

@NgModule({
  declarations: [AppComponent, FloorPipe],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    StructureModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatMenuModule,
    MatDatepickerModule,
    MatSelectModule,
    SnackbarModule,
    HttpClientModule,
    MatBadgeModule,
    StoreModule.forRoot<AppState>(
      {
        institutions: institutionReducer,
        draftInfo: draftInfoReducer,
        loadedDraft: loadedDraftReducer,
        transactions: transactionReducer,
        selectedMonth: selectedMonthReducer,
        transactionRules: transactionRulesReducer,
        accounts: accountsReducer,
        spendingComponent: spendingComponentReducer,
        subscription: subscriptionReducer,
        login: loginReducer,
        userData: userDataReducer,
        coach: coachReducer,
        payoutRequests: payoutRequestReducer,
        reportsCards: reportsCardsReducer,
        trackingToggle: trackingToggleReducer
      },
      {
        runtimeChecks: {
          strictActionTypeUniqueness: true
        }
      }
    ),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([
      InstitutionEffects,
      DraftInfoEffects,
      LoadedDraftEffects,
      TransactionEffects,
      SelectedMonthEffects,
      ScreenEffects,
      TransactionRulesEffects,
      AccountEffects,
      SubscriptionEffects,
      LoginEffects,
      UserDataEffects,
      IntercomEffects,
      CoachEffects,
      PayoutRequestsEffects,
      ReportsCardsEffects,
      TrackingToggleEffects
    ]),
    ReactiveComponentModule
  ],
  exports: [],
  providers: [
    ThemeService,
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler
    },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    GoogleAnalyticsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
